<template>
  <div class="main-body">
    <div class="storage-mode">
      <div class="page-attention">
        <p>图片存储方式设置。</p>
        <p>
          只能有一种存储方式处于启用状态, 不要随意切换存储引擎以免图片失效。
        </p>
        <p>
          如果所有存储方式都停用或设置错误都会导致图片、视频、文档上传失败。
        </p>
      </div>
      <a-radio-group v-model:value="value" v-if="mode_list.length > 0">
        <div class="storage-item" v-for="(item, index) in mode_list" :key="index">
          <a-row type="flex" justify="space-around">
            <a-col :span="4" class="item-txt">{{ item.title }}</a-col>
            <a-col :span="10" class="item-txt">{{ item.description }}</a-col>
            <a-col :span="4" class="item-txt" style="text-align: center">
              <a-radio :value="item.id" @change="changeItem">启用</a-radio>
            </a-col>
            <a-col :span="4" class="item-txt" style="text-align: right">
              <a-button type="primary" size="small" @click="showModal(item)">配置</a-button>
            </a-col>
          </a-row>
        </div>
      </a-radio-group>
      <a-skeleton v-else />
    </div>
  </div>

  <a-modal v-model:visible="visible" :title="title" :maskClosable="false" @ok="handleOk">
    <a-form :model="formState" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
      <a-form-item v-for="(item, index) in formState.fields" :key="index" :label="item.title">
        <a-input v-model:value="item.value" :placeholder="item.placeholder" />
      </a-form-item>

      <a-form-item label="访问域名">
        <a-input v-model:value="formState.domain" />
      </a-form-item>
      <a-form-item label="图片格式" extra="允许上传的图片后缀，多个使用英文','隔开.">
        <a-input style="width: 254px" v-model:value="formState.image_ext" />
        <a-input style="width: 100px" v-model:value="formState.image_size" suffix="MB" />
      </a-form-item>
      <a-form-item label="视频格式" extra="允许上传的视频后缀，多个使用英文','隔开.">
        <a-input style="width: 254px" v-model:value="formState.video_ext" />
        <a-input style="width: 100px" v-model:value="formState.video_size" suffix="MB" />
      </a-form-item>
      <a-form-item label="音频格式" extra="允许上传的视频后缀，多个使用英文','隔开.">
        <a-input style="width: 254px" v-model:value="formState.audio_ext" />
        <a-input style="width: 100px" v-model:value="formState.audio_size" suffix="MB" />
      </a-form-item>
      <a-form-item label="附件格式" extra="允许上传的附件后缀，多个使用英文','隔开.">
        <a-input style="width: 254px" v-model:value="formState.file_ext" />
        <a-input style="width: 100px" v-model:value="formState.file_size" suffix="MB" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { defineComponent, reactive, ref, onMounted } from "vue";
// import { PlusOutlined, LoadingOutlined } from "@ant-design/icons-vue";
import { message, Modal } from "ant-design-vue";
import * as Api from "@/addons/storage/api";

export default defineComponent({
  setup() {
    const title = ref("本地存储");
    const value = ref(1);
    const visible = ref(false);

    const mode_list = ref([]);

    //配置信息
    const formState = reactive({
      code: "",
      fields: [],
      domain: "",
      image_size: "",
      image_ext: "",
      video_size: "",
      video_ext: "",
      audio_ext: "",
      audio_size: "",
      file_size: "",
      file_ext: "",
    });

    // 判断是否设置完整
    const changeItem = (e) => {
      Modal.confirm({
        title: "确定要更改存储方式吗？",
        content: "请先配置参数、配置不正确将导致上传无法使用",
        onOk() {
          Api.change({ id: e.target.value }).then((res) => {
            if (res.status === 200) {
              message.success(res.message);
            } else {
              message.error(res.message);
            }
          });
        },
        onCancel() {
          onLoadData();
          console.log("Cancel");
        },
      });
    };

    // 打开弹框 编辑配置
    const showModal = (item) => {
      let setting = item.setting;
      formState.code = item.code;
      formState.fields = setting.fields;
      formState.domain = setting.domain;
      formState.image_size = setting.image_size;
      formState.image_ext = setting.image_ext;
      formState.video_size = setting.video_size;
      formState.video_ext = setting.video_ext;
      formState.audio_size = setting.audio_size;
      formState.audio_ext = setting.audio_ext;
      formState.file_size = setting.file_size;
      formState.file_ext = setting.file_ext;

      title.value = item.title;
      visible.value = true;
    };

    // 提交配置
    const handleOk = () => {
      Api.update_engine(formState).then((res) => {
        message.success(res.message);
        onLoadData();
      });
      visible.value = false;
    };

    // 初始化获取左侧导航
    onMounted(() => {
      onLoadData();
    });

    const onLoadData = () => {
      Api.engine_list().then((res) => {
        console.log(res);
        mode_list.value = res.data;
        res.data.forEach((element) => {
          if (element.status == 1) {
            value.value = element.id;
          }
        });
      });
    };

    return {
      title,
      value,
      changeItem,
      visible,
      showModal,
      handleOk,
      mode_list,
      formState,
    };
  },
});
</script>
<style scoped lang="less">
.storage-mode {
  min-height: 600px;
  .storage-item {
    width: 700px;
    padding: 10px 6px;
    margin-top: 10px;
    border: 1px solid #f2f2f2;
    .item-txt {
      font-size: 14px;
    }
  }
}
</style>